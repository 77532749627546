<template>
  <div class="onlive_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: false }"></v-header>
    </div>
    <iframe :src="'https://m.lottery.gov.cn/kjzb/?from=timeline&isappinstalled=0'"></iframe>
    <v-footer></v-footer>
  </div>
</template>

<script>
import vFooter from "@/components/footer.vue";
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'


export default {
  components: {
    vHeader,
    vFooter,
  },
  data() {
    return {
      title: this.$route.meta.title,
      active: 0,
      time: new Date().getTime(),
      showHeader:true
    };
  },
  methods: {

  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
};
</script>

<style lang="less">
.onlive_page {
  iframe {
    width: 100%;
    height: calc(100vh - 94px);
  }
}
</style>